export const colonoscopia = {
  label: "Colonoscopia",
  doctors: [
    {
      slug: 'dr_cristiano_denoni_freitas',
      clinics: ['endogastro']
    },
    {
      slug: 'dr_joao_paulo_farias',
      clinics: ['endogastro']
    }
  ]
}

export const endoscopia = {
  label: "Endoscopia",
  doctors: [
    {
      slug: 'dr_joao_paulo_farias',
      clinics: ['endogastro']
    }
  ]
}

export default [colonoscopia, endoscopia]
