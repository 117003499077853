import React, { useReducer, useCallback } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Flex from "../../components/flex"
import Typography from "../../components/typography"
import DoctorsAvatar from "../../components/doctorsAvatar"
import Place from "../../components/workplacesPlace"
import doctors from "../../api/doctors"
import exams from "../../api/exams"
import Image from "../../assets/images/equipe_gastrocirurgica_jaleco.png"
import Select from "../../components/select"
import clinics from "../../api/clinics"

const StyledImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 220px;
`

const initialState = {
  exam: null,
  doctor: null,
}

const SET_EXAM = "SET_EXAM"
const SET_DOCTOR = "SET_DOCTOR"
const SET_CLINIC = "SET_CLINIC"

const doctorsBySlug = doctors.reduce((red, doctor) => ({
  ...red,
  [doctor.slug]: doctor
}), {})

const clinicsBySlug = clinics.reduce((red, clinic) => ({
  ...red,
  [clinic.slug]: clinic
}), {})

const reducer = (state, { type, payload }) => {
  const actionTypes = {
    [SET_EXAM]: () => ({
      ...state,
      exam: payload,
    }),
    [SET_DOCTOR]: () => ({
      ...state,
      doctor: payload,
      clinics: payload.clinics.map(slug => clinicsBySlug[slug]),
      clinic: clinicsBySlug[payload.clinics[0]]
    }),
    [SET_CLINIC]: () => ({
      ...state,
      clinic: payload,
    }),
  }
  const action = actionTypes[type]
  return typeof action === "function" ? action() : state
}

const Exams = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const setDoctor = useCallback(
    payload => dispatch({ type: SET_DOCTOR, payload }),
    [dispatch]
  )
  const setExam = useCallback(
    payload => dispatch({ type: SET_EXAM, payload }),
    [dispatch]
  )
  const setClinic = useCallback(
    payload => dispatch({ type: SET_CLINIC, payload }),
    [dispatch]
  )
  return (
    <Layout {...props}>
      <SEO
        title="Exames de Colonoscopia e Endoscopia realizados pela equipe de cirurgiões da GastroCirúrgica em Florianopólis/SC"
        description="Veja as clínicas em que os cirurgiões da GastroCírurgica realizam exames"
      />
      <Section padding="1rem 0 2rem 0">
        <Container>
          <Flex flexWrap="wrap-reverse" align="center" justify="space-between">
            <Flex child flex={100} flexGtSm={35} style={{ paddingTop: "2rem" }}>
              <Typography variant="h1" component="h1" color="secondary">
                Marcar Exames
              </Typography>
              <Typography variant="h5" component="h2" color="primary" pre>
                A equipe da GastroCirúrgica realiza exames de Colonoscopia e
                Endoscopia em clínicas parceiras.
              </Typography>
              <Select
                label="Selecione um exame:"
                options={exams}
                value={state.exam}
                onChange={setExam}
                renderItem={value => (
                  <Typography variant="bodyLarge" removeMargin>
                    {value.label}
                  </Typography>
                )}
              />
              {state.exam && (
                <Select
                  label="Selecione um médico:"
                  options={state.exam.doctors}
                  value={state.doctor}
                  onChange={setDoctor}
                  renderItem={value => (
                    <Flex align="center">
                      <Flex child size="initial" style={{ maxWidth: "70px" }}>
                        <DoctorsAvatar
                          enabled={{ [value.slug]: true }}
                        />
                      </Flex>
                      <Flex child size="grown">
                        <Typography variant="bodyLarge" removeMargin>
                          {doctorsBySlug[value.slug].name}
                        </Typography>
                        <Typography variant="bodySmall">{`CRM ${doctorsBySlug[value.slug].crm}`}</Typography>
                      </Flex>
                    </Flex>
                  )}
                />
              )}
              {state.clinics && state.clinics.length > 1 && (
                <Select
                  label="Selecione uma clínica:"
                  options={state.clinics}
                  value={state.clinic}
                  onChange={setClinic}
                  renderItem={value => (
                    <Flex column>
                      <Flex child>
                        <Typography variant="bodyLarge" removeMargin>
                          {value.name}
                        </Typography>
                      </Flex>
                      <Flex child>
                        <Typography variant="bodySmall">{`${value.city}`}</Typography>
                      </Flex>
                    </Flex>
                  )}
                />
              )}
            </Flex>
            <Flex child flex={100} flexGtSm={35}>
              <StyledImage
                src={Image}
                alt="Equipe GastroCirurgica"
              />
            </Flex>
          </Flex>
          {state.clinic && <Place hideDoctors {...state.clinic} />}
        </Container>
      </Section>
    </Layout>
  )
}

export default Exams
